<script>
	import moment from "moment";
	import Loading from "@/components/Loading.vue";

	export default {
		components: {
			Loading
		},
		props: {
			data: {
				type: Array,
				required: true,
			},
			loading: {
				type: Boolean,
				required: true,
			},
		},
		methods: {
			//EMIT
			openModal(registro) {
				this.$emit('openModal', registro);
			},
			downloadGallery(registro) {
				this.$emit('downloadGallery', registro);
			},
			editGallery(registro) {
				this.$emit('editGallery', registro);
			},
			deleteGallery(registro) {
				this.$emit('deleteGallery', registro);
			},
			statusGallery(registro) {
				this.$emit('statusGallery', registro);
			},
			formatDate(date) {
				return moment(date).format('DD/MM/YYYY');
			},
			handleLikeIconClass(likes) {
				if (likes > 0 && likes !== null) return true;
				else return false;
			},
		}
	}
</script>
<template>
	<div class="row">

		<Loading 
			v-if="loading"
			pixeles="150px"
		/>

		<template v-if="!loading">	
			<div   
				class="col-12 col-md-6 col-lg-4"
				v-for="(galeria, i) in data"
				:key="i"
			>
			<article class="card card-item card-shadow border-round-10 border-0 mb-3">
				<figure class="card-item-figure">
					<div class="card-item-config">
					<a 
						href="javascript:" 
						@click="openModal(galeria)"
						data-bs-toggle="modal" 
						data-bs-target="#modal-gallery"
						class="card-item-config-btn"
						content="Ver álbum" 
						v-tippy="{ arrow: true }"
					>
						<font-awesome-icon icon="eye"/>
					</a>
					<a 
						href="javascript:" 
						@click="downloadGallery(galeria); nombreGaleria = galeria.titulo_galeria;"
						class="card-item-config-btn"
						content="Descargar álbum"
						v-tippy="{ arrow: true }"
					>
						<font-awesome-icon icon="download"/>
					</a>
					<a 
						href="javascript:" 
						@click="editGallery(galeria.id_galeria)"
						class="card-item-config-btn"
						content="Editar álbum" 
						v-tippy="{ arrow: true }"
					>
						<font-awesome-icon icon="pen"/>
					</a>
					<a 
						href="javascript:" 
						@click="deleteGallery(galeria.id_galeria)"
						class="card-item-config-btn"
						content="Eliminar álbum"
						v-tippy="{ arrow: true }" 
					>
						<font-awesome-icon icon="trash-alt"/>
					</a>
					<a 
						href="javascript:" 
						@click="statusGallery(galeria)"
						class="card-item-config-btn"
						:content="(galeria.id_estado_fk == 1 ? 'Deshabilitar' : 'Habilitar') + ' álbum' "
						v-tippy="{ arrow: true }" 
					>
						<font-awesome-icon :icon="galeria.id_estado_fk == 1 ? 'ban' : 'check-circle'"/>
					</a>
					</div>
	
					<div class="card-item-future" v-if="galeria.id_estado_fk == '3'">
					<h5 class="mb-0 text-center text-white">
						<strong>Álbum deshabilitado</strong>
					</h5>
					</div>
	
					<span class="card-item-date">
					{{ formatDate(galeria.fecha_galeria) }}
					</span>
					<img 
					:src="galeria.imagenes[0]?.url_imagen_original"
					class="card-item-figure-img"
					>
				</figure>
				<div class="card-body card-item-excerpt">
				<h5 class="card-item-title-1 font-main color-gray">
					<span v-if="galeria.titulo_galeria !== undefined">
						{{ galeria.titulo_galeria }}
					</span>
					<span v-if="galeria.titulo_galeria === undefined">
						Album sin título
					</span>
				</h5>
				<div class="card-item-data">
					<span>
					
					{{ galeria.imagenes.length }}
					{{ galeria.imagenes.length > 1 ? "Fotos" : "Foto" }}
					</span>
					<span>
					<i 
						v-if="handleLikeIconClass(galeria.total_likes_admin)"
						class="fa-solid fa-heart color-rose-shadow"
					>
					</i>
					<i 
						v-else
						class="fa-regular fa-heart color-main">
					</i>
					{{ galeria.total_likes_admin == null ? 0 : galeria.total_likes_admin }}
					Me gusta
					</span>
				</div>
				</div>
			</article>
			</div>
		</template>
	</div>
</template>