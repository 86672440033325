<script>
	export default {
		props: {
			data: {
				type: Object,
				required: true,
			}
		},
		data() {
			return {
				indexActual: 0
			}
		},
		methods: {
			//EMIT
			editGallery() {
				this.$emit('editGallery', this.data.id_galeria);
			},
			downloadGallery() {
				this.$emit('downloadGallery', this.data.imagenes[this.indexActual], this.data);
			},
		},
		watch: {
			data() {
				const carousel = document.getElementById('carousel-gallery');
				carousel.addEventListener('slide.bs.carousel', event => {
					this.indexActual = JSON.parse(event.relatedTarget.id);
				})
			}
		},
	}
</script>

<template>
<div 
	class="modal fade" 
	id="modal-gallery" 
	tabindex="-1" 
	aria-hidden="true"
>
	<div class="modal-dialog modal-xl">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title color-main font-700"> 
					<i class="fa-regular fa-images"></i>
					{{ data.titulo_galeria }}
				</h3>
				<button 
					type="button" 
					class="modal-custom-close"
					data-bs-dismiss="modal" 
					aria-label="Close">
					<font-awesome-icon icon="xmark"/>
				</button>
			</div>
			<div class="modal-body">
				<div 
					id="carousel-gallery" 
					class="carousel slide"
				>
					<div class="carousel-inner">
						<div 
							v-for="(img, index) in data.imagenes" 
							:key="img.id_imagen"
							:id="index"
							class="carousel-item"
							:class="index === 0 ? 'active' : '' "
						>
							<img 
								:src="img.url_imagen_original" 
								class="d-block w-100 m-auto"
								style="object-fit: scale-down; max-width: 33em;"
							/>
							<div class="row position-absolute bottom-0 end-0">
								<div class="col-12">
									<h6 class="p-2 m-3 bg-light bg-opacity-75 rounded">
										<span v-if="img.likes?.length > 0">
											<i class="fa-solid fa-heart color-red"></i>
										</span>
										<span v-if="img.likes?.length === 0">
											<i class="fa-regular fa-heart"></i>
										</span>
										{{ img.likes && img.likes?.length }}
									</h6>
								</div>
							</div>
						</div>
					</div>
					<button 
						v-if="data.imagenes?.length > 1"
						class="carousel-control-prev" 
						type="button" 
						data-bs-target="#carousel-gallery" 
						data-bs-slide="prev"
					>
						<span class="bg-light bg-opacity-25 p-2 rounded">
							<i class="h1 mb-0 fa-solid fa-angle-left color-main"></i>
						</span>
					</button>
					<button 
						v-if="data.imagenes?.length > 1"
						class="carousel-control-next" 
						type="button" 
						data-bs-target="#carousel-gallery" 
						data-bs-slide="next"
					>
						<span class="bg-light bg-opacity-25 p-2 rounded">
							<i class="h1 mb-0 fa-solid fa-angle-right color-main"></i>
						</span>
					</button>
				</div>
			</div>
			<div class="modal-footer">
				<button 
					@click="downloadGallery()"
					type="button" 
					class="btn btn-custom btn-custom-color-white border-round-50 w-150"
				>
						<i class="fa-solid fa-download me-2"></i>
						Descargar
					</button>
				<button 
					@click="editGallery()"
					type="button" 
					class="btn btn-custom btn-custom-color-blue border-round-50 w-150"
					data-bs-dismiss="modal" 
				>
					<i class="fa-solid fa-pencil me-2"></i>
					Editar
				</button>
			</div>
		</div>
	</div>
</div>
</template>